<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.add_combo") }}
      <v-spacer></v-spacer>
      <a
        class="mr-1 v-btn v-btn--outlined theme--light v-size--small default"
        :href="templateLink"
        target="_blank"
      >
        <v-icon>mdi-download</v-icon>
        {{ $t("labels.sample_file") }}
      </a>
      <v-btn
        color="primary"
        small
        outlined
        class="mr-1"
        @click="$refs.inputUploadFile.click()"
      >
        <v-icon>mdi-upload</v-icon>
        {{ $t("labels.upload") }}
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        accept=".xlsx"
        @change="onInputFileChange"
        class="d-none"
      />

      <v-btn
        color="success"
        small
        outlined
        class="mr-1"
        @click="confirm"
        :disabled="isDisabled"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" small outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 300px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center" style="width: 120px">
                {{ $t("labels.combo_code") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 180px">
                {{ $t("labels.combo_name") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 110px">
                {{ $t("labels.price") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 110px">
                {{ $t("labels.wholesale_price") }}
              </th>
              <th role="columnheader" class="text-center">
                <v-row dense>
                  <v-col cols="9">{{ $t("labels.barcode") }}</v-col>
                  <v-col cols="3">{{ $t("labels.quantity") }}</v-col>
                </v-row>
              </th>
              <th role="columnheader" class="text-center" style="width: 125px">
                <v-btn small color="success" @click="addRow">
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="`cbg_${index}`">
              <td class="text-center">
                <v-text-field
                  v-model="item.barcode"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.name"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </td>
              <td>
                <input-number
                  v-model.number="item.price"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td>
                <input-number
                  v-model.number="item.wholesale_price"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td class="text-center">
                <v-row
                  dense
                  v-for="(child, ci) in item.childrens"
                  :key="`ci_${ci}`"
                >
                  <v-col cols="9">
                    <SelectSuggestGoodsLimit
                      :index="index"
                      :obj="{ item, child, ci }"
                      :default-value="child.customer_goods_barcode"
                      @onSelect="selectItem"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model.number="child.quantity"
                      class="c-input-xs"
                      type="number"
                      min="1"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td class="text-right">
                <v-btn
                  color="primary"
                  x-small
                  class="mr-1"
                  @click="addChild(item, index)"
                >
                  {{ $t("labels.add_item") }}
                </v-btn>
                <v-btn color="error" x-small @click="removeItem(index)">
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "ComboCreateMulti",
  components: {
    SelectSuggestGoodsLimit: () =>
      import("@/components/common/SelectSuggestGoodsLimit"),
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  computed: {
    isDisabled() {
      if (!this.items || this.items.length === 0) {
        return true;
      }

      let isError = false;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (!item.name || !item.barcode) {
          isError = true;
        }
        if (item.childrens.length === 1 && item.childrens[0].quantity < 2) {
          isError = true;
        }
        for (let c = 0; c < item.childrens.length; c++) {
          const child = item.childrens[c];
          if (child.quantity < 1 || !child.customer_goods_barcode) {
            isError = true;
          }
        }
      }

      return isError;
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_combo.xlsx`;
    },
  },
  mounted() {},
  methods: {
    addRow() {
      this.items.push({
        barcode: "",
        name: "",
        price: "",
        wholesale_price: "",
        childrens: [
          {
            customer_goods_barcode: "",
            quantity: 1,
          },
        ],
      });
    },
    addChild(item, index) {
      const items = [...this.items];
      item.childrens.push({
        customer_goods_barcode: "",
        quantity: 1,
      });
      items[index] = { ...item };
      this.items = [...items];
    },
    removeItem(index) {
      this.items = [...this.items].filter((obj, key) => key !== index);
    },

    selectItem({ item, index, obj }) {
      if (!item.customer_goods_barcode) {
        return false;
      }
      const { child, ci } = obj;
      const items = [...this.items];
      const childrens = [...items[index].childrens];
      childrens[ci] = { ...child, ...item };
      items[index].childrens = [...childrens];
      this.items = [...items];
    },
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;

      const mappingFields = {
        "Mã comboCombo ID": "barcode",
        "Tên comboCombo Name": "name",
        "Giá lẻRetail Price": "price",
        "Giá buônWholesale Price": "whosale_price",
        Barcode: "customer_goods_barcode",
        "Số lượngQuantity": "quantity",
      };

      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = utils.sheet_to_json(worksheet);

          const items = [...values].map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              const mapkey = i.replace(/\r?\n|\r/g, "");
              item[mappingFields[mapkey]] = `${v[i]}`.trim();
            });
            return item;
          });
          this.validateGoods(items);
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    validateGoods(items) {
      let data = [];
      items.forEach((item) => {
        const checkItem = data.find((d) => d.barcode == item.barcode);
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.barcode == item.barcode
          );
          data[checkItemIndex].childrens.push({
            customer_goods_barcode: item.customer_goods_barcode,
            quantity: +item.quantity,
          });
        } else {
          const childrens = [
            {
              customer_goods_barcode: item.customer_goods_barcode,
              quantity: +item.quantity,
            },
          ];
          data.push({
            childrens,
            barcode: item.barcode,
            name: item.name,
            price: item.price || item.price == "0" ? +item.price : null,
            wholesale_price:
              item.wholesale_price || item.wholesale_price == "0"
                ? +item.wholesale_price
                : null,
          });
        }
      });
      this.items = [...data];
    },

    cancel() {
      this.$emit("cancel");
    },

    async confirm() {
      if (this.isDisabled) {
        return false;
      }

      const errorIndexs = [];

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const checkChild = [...item.childrens].find(
          (child) => !child.customer_goods_barcode || !child.quantity
        );
        if (checkChild) {
          errorIndexs.push(i + 1);
        }
      }

      if (errorIndexs.length > 0) {
        this.$vToastify.warning(
          this.$t("messages.error_rows", { rows: errorIndexs.join("; ") })
        );
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const items = [...this.items].map((item) => ({
        ...item,
        barcode: (item.barcode && item.barcode.toUpperCase()) || null,
      }));
      try {
        await httpClient.post("/goods-combo-create-multi", {
          items,
        });
        this.isLoading = false;
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
